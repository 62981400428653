/**
 * メニュー用モジュール。
 * @module app/convocation/view/ConvoMenu
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/has',
    'dojo/text!./templates/ConvoMenu.html',
    'dojo/topic',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    // 以下、変数で受けないモジュール
    'dojo/query' // onでdelegationする際に必要
], function(module, declare, lang, domClass, has, template, topic, _WidgetBase, _TemplatedMixin,
            Locator, Router, UserInfo, ACL) {
    /**
     * リンクの情報を管理するオブジェクト。
     * @typedef LinkInfo
     * @property {string} title リンクのtitle属性（カーソルを合わせたときの表示）
     * @property {string} label リンク文字列
     * @property {string} page ページ識別子、指定時はhrefよりも優先
     * @property {string} href リンク先
     * @property {LinkInfo[]} links リンクの情報の配列
     */

    /**
     * メニュー用オブジェクト
     * @class ConvoMenu
     */
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin],
        /** @lends app/convocation/view/ConvoMenu~ConvoMenu# */ {
        // テンプレート文字列
        templateString: template,

        MENU_TYPE_FLAT: 'フラット型メニュー',
        MENU_TYPE_HIERARCHY: '階層型メニュー',

        menuLabelOpen: '▼ メニュー',
        menuLabelClose: '▶︎ メニュー',

        /**
         * リンク情報の配列
         * @type {module:app/convocation/view/ConvoMenu~LinkInfo[]}
         */
        _links: null,

        href: null,

        // DOM要素準備後に呼ばれる
        buildRendering: function() {
            this.inherited(arguments);

            var message = UserInfo.getId() + ' は';
            if(UserInfo.getRoleCd() === ACL.ADMIN_USER) {
            	message = message + '管理ユーザです。';
            } else if(UserInfo.getRoleCd() === ACL.HQ_USER) {
            	message = message + '本部ユーザです。';
            } else if(UserInfo.getRoleCd() === ACL.DEPT_USER) {
            	message = message + '部局ユーザです。';
            } else if(UserInfo.getRoleCd() === ACL.CITY_H_USER) {
            	message = message + '政令指定市ユーザです。';
            } else if(UserInfo.getRoleCd() === ACL.CITY_USER) {
            	message = message + '市町村ユーザです。';
            } else if(UserInfo.getRoleCd() === ACL.FIRE_USER) {
            	message = message + '消防ユーザです。';
            } else if(UserInfo.getRoleCd() === ACL.OTHER_USER) {
            	message = message + 'その他ユーザです。';
            } else {
            	message = message + '未定義ユーザです。 役割コード:' + UserInfo.getRoleCd();
            }
            console.debug(message);
            console.debug('ACL ADMIN : ' + UserInfo.getAcl().ADMIN);
            console.debug('ACL CHIEF ADMIN: ' + UserInfo.getAcl().CHIEF_ADMIN);

            // リンク一覧を生成
            this._links = [];

            if(this.getMenuType() === this.MENU_TYPE_FLAT) {
            	// メニュータイプ：フラット型
            	this._links = this.createMenuTypeFlatLinks();
            } else if(this.getMenuType() === this.MENU_TYPE_HIERARCHY) {
            	// メニュータイプ：階層型
            	this._links = this.createMenuTypeHierarchyLinks();
            } else {
            	// メニュータイプ：階層型
            	this._links = this.createMenuTypeHierarchyLinks();
		    	console.warn('メニュータイプが未定義です。 メニュータイプ:' + this.getMenuType());
            }

            this._links.push();
            this.updateLinks();
            this.own(Locator.on('change', lang.hitch(this, 'updateLinks')));
        },

        postCreate: function() {
            this.inherited(arguments);
            // リンクがクリックされたら反応する
            this.on('a:click', lang.hitch(this, function(e) {
                this.href = e.target.href;

                if(this.href.indexOf(Locator.getHref()) === -1 &&
                    Locator.getHref().indexOf(this.href) === -1) {
                    this.isMenuOpen = false;
                }

                if (!Locator.isCurrentPath(this.href)) {
                    // ブラウザーの遷移処理をキャンセル
                    e.preventDefault();
                    // ページへ遷移
                    Locator.pushState(Locator.getQueryFrom(this.href), true);
                }
                // ページ指定になっていない場合はそのまま通す
            }));

            this.own(Locator.on('change', lang.hitch(this, function() {

                console.debug('this.href = ' + this.href);
                console.debug('Locator.getHref() = ' + Locator.getHref());

                if(!(this.href === Locator.getHref() ||
                        this.href === Locator.getHref() + '#')) {
                    this.isMenuOpen = false;
                }
            })));

        },

        /**
         * リンク一覧のDOMノードを更新する。
         */
        updateLinks: function() {

            // 新たなリンク一覧を文字列として構築
            //var currentPath = Router.getCurrentPath();
            var html = [];
            for (var i = 0; i < this._links.length; i++) {
                var link = this._links[i];
                html.push('<li><a href="');
                if(link.links) {
                	html.push('#">');
                    html.push(link.label);
                    html.push('</a><ul>');
                    for(var j = 0; j < link.links.length; j++) {
                    	var subLink = link.links[j];
                    	html.push('<li><a href="');
                        html.push(subLink.page ? ('?' + Router.PAGE_KEY + '=' + subLink.page) : (link.href || '#'));
                        html.push('" title="');
                        html.push(subLink.title);
                        html.push('">');
                        html.push(subLink.label);
                        html.push('</a></li>');
                    }
                    html.push('</ul></li>');
                } else {
                    // 市町ユーザーの場合、メニューから一覧画面へ直接遷移する
                    if (!link.municipalityCd) {
                        html.push(link.page ? ('?' + Router.PAGE_KEY + '=' + link.page) : (link.href || '#'));
                    }else{
                        html.push(link.page ? '?municipalityCd=' + link.municipalityCd +
                                '&' + Router.PAGE_KEY + '=' + link.page : (link.href || '#'));
                    }
                    html.push('" title="');
                    html.push(link.title);
                    html.push('">');
                    html.push(link.label);
                    html.push('</a></li>');
                }
            }

            // リンク一覧の中身を置き換える
            this.linkList.innerHTML = html.join('');
        },

        isMenuOpen: false,

        onMenuLinkClick: function() {
            console.debug('メニューリンクがクリックされました');

            if(!this.isMenuOpen) {
                console.debug('メニューリンクをオープンします。');
                this.isMenuOpen = true;
            } else {
                console.debug('メニューリンクをクローズします。');
                this.isMenuOpen = false;
            }
        },

        getMenuType : function() {
            var menuType = '';

            menuType = this.MENU_TYPE_FLAT;

            console.debug('メニュータイプ：' + menuType);

            return menuType;
        },

        createMenuTypeFlatLinks : function() {
            var links = [];

            if (UserInfo.hasAuthz('F08001')) {
                links.push({
                    page: 'convocation/mail',
                    label: '職員招集メール配信管理',
                    title: 'メール配信、および、配信メールの管理を行います。'
                });
            }
            if (UserInfo.hasAuthz('F08002')) {
                links.push({
                    page: 'convocation/employee',
                    label: '職員管理',
                    title: '職員の管理を行います。'
                });
                links.push({
                    page: 'convocation/group',
                    label: 'グループ管理',
                    title: 'グループの管理を行います。'
                });
                links.push({
                    page: 'convocation/automail',
                    label: '配信条件設定管理',
                    title: '配信条件の管理を行います。'
                });
                if (UserInfo.getRoleCd() === ACL.ADMIN_USER) {
                    links.push({
                        page: 'convocation/user',
                        label: '職員招集管理者管理',
                        title: '職員招集管理者情報を登録・更新・削除します。'
                    });
                }
            }

            return links;
        },

        createMenuTypeHierarchyLinks : function() {
            return [];
        }
    });
});
