/**
 * 避難所情報のユーティリティー関数群。
 * @module app/shelter/ShelterUtils
 */
define([
    'module',
    'dojo/date',
    'idis/util/DateUtils',
    'app/AppUtils'
], function(module, date, DateUtils, AppUtils) {
    /**
     * 指定された要素が開設中か否かをチェックする関数を返す。
     * 開設中の条件：
     * 『開設日時があり開設日時が基準日時よりも前かつ閉鎖日時がない』または閉鎖日時が基準日時よりも後
     * @param {Date} target 基準日時
     * @returns {function} 指定された要素が開設中か否かをチェックする関数
     */
   function _isOpenOn() {
       return function(item) {
           if (AppUtils.isEmpty(item.shelterStartTimestamp)) {
               // 開設日時の入力がない
               return false;
           }
           if (AppUtils.isEmpty(item.shelterEndTimestamp)) {
               // 閉鎖日時の入力がない
               return true;
           }
           return false;
       };
    }

    return {
        isOpenOn: _isOpenOn
    };
});
