/* 開発環境用設定ファイル */
define({
    /**
     * geocode関連
     */
    geocode: {
        // geocodeサービスのURL
        url: process.env.GEOCODE_SERVICE_URL
    },

    map: {
        /**
         * 使用する背景地図一覧（各背景地図情報はapp/map/baselayer/BaseLayerOptionsで定義）
         * 指定されない場合は全選択肢を表示する。
         * @type {identifier[]}
         */
        userBaseLayers: {'INTERNET': ['OSM_ST','GSI_PALE_NET','GSI_STANDARD_NET','GSI_ORT_NET','ESRI_IM','ESRI_ST','GSI_VECTOR'],
                            'LGWAN': ['GSI_PALE_NET_LGWAN','GSI_STANDARD_NET_LGWAN','GSI_ORT_NET_LGWAN']},
        saveImageBaseLayers: {'INTERNET': ['GSI_PALE_NET','GSI_STANDARD_NET','GSI_ORT_NET','GSI_VECTOR'],
                            'LGWAN': ['GSI_PALE_NET_LGWAN','GSI_STANDARD_NET_LGWAN','GSI_ORT_NET_LGWAN']},
        url: process.env.MAP_URL,
        latitude: 35.689568,
        longitude: 139.691717
    },

    municInfo: {
        prefCd: '130000', //東京都の県コード
        prePrefCd: '13', //東京都の県コードの上2桁
        prefMunicCd: '130001', //東京都の市町村コード(M_MUNICIPALITYにおける東京都のコード)
        prefName: '東京都', //県名
        defaultMunicCd: '131041', //東京都新宿区の市町村コード
        defaultMunicCdForSimpleReport: '131041', // (被害情報で利用)デフォルトの市町コード 長崎県の市町村コード
        cityDistrictMunicCds: [],
        parentCdsForSpecialCities: ['131008'], // 県内の政令指定都市(東京都では特別区部を政令指定都市とする)の市町村コード,
        cityMunicCd: '011002', // 特別区部の市町村コード
        wardList: ['131016', '131024', '131032', '131041', '131059', '131067',
            '131075', '131083', '131091', '131105', '131113', '131121', '131130',
            '131148', '131156', '131164', '131172', '131181', '131199', '131202',
            '131211', '131229', '131237'], // 政令指定都市内の区
        prefRegCdGudge: ['131001', '131002', '132001', '132002', '132003', '133001'],//地域(Region)コード一覧
        kisyoAreaCd: '420012', //操作主体の気象エリアコード。気象ページの地区セレクターの制御に利用。
        cityMunicDistrictCd: '201000', //地区コード。地区セレクターの制御に利用。
        cities4multiDistShare: [], // 避難情報で、複数地区で同じポリゴンを多重に共有している市町村（5桁版コード）
        otherRelationOrg: '990001'//その他の行政機関
    },

    layerPubStatus: {
        roleList: ['R01001', 'R01011', 'R01012', 'R01912', 'R03012']
    },


    dept: {
        soumuDeptCd: 'D02000',
        fireDepartmentDeptCd: 'D46000',
        crisisManagementDeptCd: 'D50000',
        humanResourceDeptCd: 'D61000'
    },

    print: {
        /**
         * 印刷サービスでArcGISサーバーからの画像取得先
         */
        getImageUrl: function () {
            // 内部向けWebサーバー
            return 'http://' + location.host;
        }
    },

    /**
     * Esri地図モジュール用の設定（モバイルで利用）
     */
    esri: {
        initExtent: [{
            ymin: 35.189568, // latitude - 0.5
            ymax: 36.189568, // latitude + 0.5
            xmin: 138.991717, // longitude - 0.7
            xmax: 140.391717 // longitude + 0.7
        }],
        bookmarks: [{
            name: '庁舎',
            ymin: 35.689568,
            ymax: 35.689568,
            xmin: 139.691717,
            xmax: 139.691717
        }]
    },/**
     * 防災ポータル情報
     */
    portalInfo: {
        url: 'http://example.com/portal'
    },

    /**
     * サーバ情報
     */
     server: {
        adisWeb: process.env.ADISWEB_PATH,
        adisTraining: process.env.ADISTRAINING_PATH
    },

    // ファイル共有サイト（外部サイト）のURL
    fileShare: {
        url: '/fileshare'
    },

    // ヘルプデスクの連絡先
    helpdesk: {
        mail: 'bousai-helpdesk@dcloud.z-hosts.com',
        tel: '050-3196-1912'
    },
    /**
     * 防災ポータル情報
     */
    portalInfo: {
        url: 'http://example.com/portal'
    },

    /**
     * サーバ情報
     */
     server: {
        adisWeb: process.env.ADISWEB_PATH,
        adisTraining: process.env.ADISTRAINING_PATH
    },

    // ファイル共有サイト（外部サイト）のURL
    fileShare: {
        url: '/fileshare'
    },

    // ヘルプデスクの連絡先
    helpdesk: {
        mail: 'bousai-helpdesk@dcloud.z-hosts.com',
        tel: '050-3196-1912'
    }
});
